<template>
  <div id="account" class="profile">
    <div class="form-container">
      <h1>Профиль</h1>
      <h3>Учетная запись</h3>
      <div class="form-container__form">
        <div class="form-container__form__set">
          <div class="row">
            <div class="col-3">
              <rwm-text-field
                  :value="email"
                  disabled
                  label="E-mail"
              />
            </div>
            <div class="col-3">
              <rwm-text-field
                  disabled
                  label="Пароль"
                  value="**********"
              >
                <div slot="append-icon">
                  <v-icon @click="$emit('EditPass')">edit</v-icon>
                </div>
              </rwm-text-field>
            </div>
          </div>
        </div>
        <delimiter style="margin-top: 32px; margin-bottom: 32px;"/>
        <h3 id="personal_data">Персональные данные</h3>
        <div class="form-container__form__set">
          <div class="row">
            <div class="col-3">
              <rwm-text-field
                  v-model="compSname"
                  :disabled="isRegistered"
                  :error="errors.sname"
                  label="Фамилия"
                  @blur="validate('sname')"
              />
            </div>
            <div class="col-3">
              <rwm-text-field
                  v-model="compName"
                  :disabled="isRegistered"
                  :error="errors.name"
                  label="Имя"
                  @blur="validate('name')"
              />
            </div>
            <div class="col-3">
              <rwm-text-field
                  v-model="compMname"
                  :disabled="isRegistered"
                  :error="errors.mname"
                  label="Отчество"
                  @blur="validate('mname')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <rwm-date-picker
                  v-model="compBirthday"
                  :disabled="isEdit"
                  :error="errors.birthday"
                  label="Дата рождения"
                  @blur="validate('birthday')"
              />
            </div>
            <div class="col-3">
              <rwm-text-field
                  v-model="compBirthplace"
                  :disabled="isEdit"
                  :error="errors.birthplace"
                  label="Место рождения - город"
                  @blur="validate('birthplace')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <rwm-autocomplete
                  :disabled="isEdit"
                  :error="errors.registrationAddress"
                  :list="addressList.registration"
                  :value="compRegistrationAddress"
                  label="Полный адрес регистрации"
                  @blur="onBlure('registrationAddress', ...arguments)"
                  @input="findList('registration', $event)"
                  @select="selectAddress('registration', $event)"
              />
            </div>
            <div class="col-2">
              <rwm-text-field label="Почтовый индекс"
                              v-model="compPostcodeRegistrationAddress"
                              :disabled="isEdit"
                              v-mask="'######'"
                              :error="errors.postcoderegistrationAddress"
                              @blur="onBlure('postcoderegistrationAddress', ...arguments)" />
            </div>
            <div class="col-12" style="margin-bottom: 12px">
              <rwm-checkbox
                  v-model="compSameAddress"
                  label="Совпадает с адресом проживания"
                  @change="isSameAddress(compSameAddress)"
                  :disabled="isEdit"
              />
            </div>
            <div class="col-9">
              <rwm-autocomplete
                  :disabled="isEdit || compSameAddress"
                  :error="errors.actualAddress"
                  :list="addressList.actual"
                  :value="compActualAddress"
                  label="Полный адрес проживания"
                  @blur="onBlure('actualAddress', ...arguments)"
                  @input="findList('actual', $event)"
                  @select="selectAddress('actual', $event)"
              />
            </div>
            <div class="col-2">
              <rwm-text-field label="Почтовый индекс"
                              :disabled="isEdit || compSameAddress"
                              v-model="compPostcodeActualAddress"
                              v-mask="'######'"
                              :error="errors.postcodeactualAddress"
                              @blur="onBlure('postcodeactualAddress', ...arguments)" />
            </div>
            <div class="col-3">
              <rwm-text-field
                  v-model="compPhone"
                  v-mask="'+# ### ### ## ##'"
                  disabled
                  label="Телефон"
                  type="phone"
              />
            </div>
          </div>
          <div class="col-12">
            <p class="anketa">Документ, удостоверяющий личность: паспорт</p>
          </div>
          <div class="row">
            <div class="col-3">
              <rwm-text-field
                  v-model="compSeria"
                  v-mask="'####'"
                  :disabled="isRegistered"
                  :error="seriaError"
                  label="Серия паспорта"
                  @blur="validatePassportNumber('seria')"
              />
            </div>
            <div class="col-3">
              <rwm-text-field
                  v-model="compNumber"
                  v-mask="'######'"
                  :disabled="isRegistered"
                  :error="numberError"
                  label="Номер паспорта"
                  @blur="validatePassportNumber('number')"
              />
            </div>
            <div class="col-3">
              <rwm-date-picker
                  v-model="compPassportIssueDate"
                  :disabled="isRegistered"
                  :error="errors.issueDate"
                  label="Дата выдачи паспорта"
                  @blur="validate('issueDate')"
              />
            </div>
            <div class="col-3">
              <rwm-text-field
                  v-model="compPassportDepartment"
                  v-mask="'###-###'"
                  :disabled="isRegistered"
                  :error="errors.department"
                  label="Код подразделения"
                  @blur="validate('department')"
              />
            </div>
            <div class="col-3">
              <rwm-input-file
                  :filename="compPassportFile && compPassportFile.length > 0 ? compPassportFile[0].name : ''"
                  refer="uploadPassport"
                  title="Прикрепить скан паспорта"
                  @FileChange="onFileLoad($event, 'passport')"
                  @deleteFile="onFileDelete({file: compPassportFile, type: compPassportFileType})"
                  @openFile="$emit('openFile', 'passport')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <rwm-autocomplete
                    label="Кем выдан паспорт"
                    v-model="compPassportIssue"
                    :error="errors.issue"
                    :list="issuedByList"
                    :disabled="isRegistered"
                    type="address"
                    @select="validate('issue')"
                    @blur="validate('issue')"
            />
            </div>
            <div class="col-3">
              <rwm-autocomplete
                      label="ИНН"
                      :value="compInn"
                      :error="errors.inn"
                      :list="innList"
                      :mask="'############'"
                      :disabled="isEdit"
                      @select="findInn"
                      @blur="findInn($event.target.value)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <rwm-text-field
                  v-model="compSnilsNumber"
                  v-mask="'###-###-### ##'"
                  :disabled="isEdit"
                  :error="errors.snils"
                  label="СНИЛС"
                  @blur="validate('snils')"
              />
            </div>
            <div class="col-3">

              <rwm-input-file
                  :disabled="isEdit"
                  :filename="snilsFileName"
                  refer="uploadPassport"
                  title="Прикрепить скан СНИЛС"
                  @FileChange="onFileLoad($event, 'snils')"
                  @deleteFile="onFileDelete({file: snilsFile, type: snilsFileType})"
                  @openFile="$emit('openFile', 'snils')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <rwm-button outlined width="100%" @click="saveUser()">{{
                  isEdit ?
                      "Редактировать" :
                      "Сохранить"
                }}
              </rwm-button>
            </div>
            <div v-if="!isEdit" class="col-3">
              <rwm-button width="100%" @click="cancelEditing()">Отменить</rwm-button>
            </div>
          </div>
          <template v-if="!isEdit && showConfirmation">
            <div class="row">
              <div class="col-3">
                <rwm-sms-checking
                    v-model="_timer"
                    :time="60"
                    isShow
                    @sendAgain="sendAgain()"
                    @verified="codeIsVerified"
                />
              </div>
            </div>
          </template>
        </div>
        <delimiter style="margin-top: 32px; margin-bottom: 32px;"/>
        <h3 id="bank_details">Банковские реквизиты</h3>
        <div class="form-container__form__set">
          <div v-if="!accountsList || accountsList.length === 0" class="col-12">
            <p class="anketa">Счета отсутствуют</p>
          </div>
          <div v-else class="col-12">
            <bank-account
                v-for="(account, index) in accountsList"
                :id="account.id"
                :key="`account${index}${account.bik}${account.bank_account}`"
                :account="account.bank_account"
                :bank="account.bank_name"
                :bik="account.bik"
                :corr-account="account.correspondent_account"
                :name="account.name"
                @editBankAccount="editAccount"
            />
          </div>
          <div class="row">
            <div class="col-3">
              <rwm-button width="100%" @click="$emit('addBankAccount')">Добавить реквизиты</rwm-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RwmTextField from "../../../components/RWMTextField/RwmTextField";
import RwmDatePicker from "../../../components/RWMDatePicker/RwmDatePicker";
import Delimiter from "../../../components/modules/Delimiter";
import RwmButton from "../../../components/RWMButton/RwmButton";
import VIcon from "../../icons/VIcon";
import BankAccount from "../../modules/BankAccount";
import RwmSmsChecking from "../../RWMSMSChecking/RwmSmsChecking";
import {validateParam, validateUserForm} from "@/models/Validation";
import {mapActions} from "vuex";
import RwmAutocomplete from "../../RWMAutocomplete/RwmAutocomplete";
import RwmCheckbox from "../../RWMCheckbox/RwmCheckbox";
import RwmInputFile from "../../RWMInputFile/RwmInputFile";

export default {
  name: "Profile",
  components: {
    RwmInputFile,
    RwmCheckbox,
    RwmAutocomplete,
    RwmSmsChecking, BankAccount, VIcon, RwmButton, Delimiter, RwmDatePicker, RwmTextField
  },
  props: {
    actualAddress: String,
    postcodeactualAddress: String,
    birthday: String,
    birthplace: String,
    email: String,
    inn: String,
    registrationMethod: [Number, String],
    passportDepartment: String,
    passportIssue: String,
    passportIssueDate: String,
    passportNumber: String,
    passportFile: Array,
    passportFileType: [String, Number],
    phone: String,
    registrationAddress: String,
    postcoderegistrationAddress: String,
    snilsNumber: [String, Number],
    snilsFile: Array,
    snilsFileType: [String, Number],
    mname: String,
    name: String,
    sname: String,
    sameAddress: Boolean,
    issuedByList: {type: Array, default: () => []},
    innList: {type: Array, default: () => []},

    isEdit: Boolean,
    accountsList: Array,
    showConfirmation: {
      type: Boolean,
      default: false
    },
    timer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRegistered() {
      return this.registrationMethod===3 ? true : this.isEdit
    },
    snilsFileName() {
      return this.snilsFile && this.snilsFile.length > 0 ? this.snilsFile[0].name : this.editedUser.snils.scan?.file && this.editedUser.snils.scan?.file.length > 0 ? this.editedUser.snils.scan?.file[0].name : ''
    },
    _timer: {
      get() {
        return this.timer
      },
      set(newVal) {
        this.$emit('timerChange', newVal)
      }
    },
    compActualAddress: {
      get() {
        return this.isEdit ? this.actualAddress : this.editedUser.actualAddress
      },
      set(value) {
        this.editedUser.actualAddress = value
      }
    },
    compPostcodeActualAddress: {
      get() {
        return this.isEdit ? this.postcodeactualAddress : this.editedUser.postcodeactualAddress
      },
      set(value) {
        this.editedUser.postcodeactualAddress = value
      }
    },
    compBirthday: {
      get() {
        return this.isEdit ? this.birthday : this.editedUser.birthday
      },
      set(value) {
        this.editedUser.birthday = value
      }
    },
    compBirthplace: {
      get() {
        return this.isEdit ? this.birthplace : this.editedUser.birthplace
      },
      set(value) {
        this.editedUser.birthplace = value
      }
    },
    compEmail: {
      get() {
        return this.isEdit ? this.email : this.editedUser.email
      },
      set(value) {
        this.editedUser.email = value
      }
    },
    compInn: {
      get() {
        return this.isEdit ? this.inn : this.editedUser.inn
      },
      set(value) {
        this.editedUser.inn = value
      }
    },
    compPassportDepartment: {
      get() {
        return this.isEdit ? this.passportDepartment : this.editedUser.passport.department
      },
      set(value) {
        this.editedUser.passport.department = value
      }
    },
    compPassportIssue: {
      get() {
        return this.isEdit ? this.passportIssue : this.editedUser.passport.issue
      },
      set(value) {
        this.editedUser.passport.issue = value
      }
    },
    compPassportIssueDate: {
      get() {
        return this.isEdit ? this.passportIssueDate : this.editedUser.passport.issueDate
      },
      set(value) {
        this.editedUser.passport.issueDate = value
      }
    },
    // compPassportNumber: {
    //   get() {
    //     return this.isEdit ? this.passportNumber :
    //   },
    //   set(value) {
    //     this.editedUser.passport.number = value
    //   }
    // },
    compPassportFile: {
      get() {
        return this.isEdit ? this.passportFile : this.editedUser.passport.scan?.file
      },
      set(value) {
        this.editedUser.passport.scan.file = value
      }
    },
    compPassportFileType: {
      get() {
        return this.isEdit ? this.passportFileType : this.editedUser.passport.scan.type
      },
      set(value) {
        this.editedUser.passport.scan.type = value
      }
    },
    compPhone: {
      get() {
        return this.isEdit ? this.phone : this.editedUser.phone
      },
      set(value) {
        this.editedUser.phone = value
      }
    },
    compRegistrationAddress: {
      get() {
        return this.isEdit ? this.registrationAddress : this.editedUser.registrationAddress
      },
      set(value) {
        this.editedUser.registrationAddress = value
      }
    },
    compPostcodeRegistrationAddress: {
      get() {
        return this.isEdit ? this.postcoderegistrationAddress : this.editedUser.postcoderegistrationAddress
      },
      set(value) {
        this.editedUser.postcoderegistrationAddress = value
      }
    },
    compSnilsNumber: {
      get() {
        return this.isEdit ? this.snilsNumber : this.editedUser.snils?.number
      },
      set(value) {
        this.editedUser.snils.number = value
      }
    },
    compSnilsFile: {
      get() {
        return !this.isEdit ? this.snilsFile : this.editedUser.snils?.scan?.file
      },
      set(value) {
        this.editedUser.snils.scan.file = value
      }
    },
    compSnilsFileType: {
      get() {
        return this.isEdit ? this.snilsFileType : this.editedUser.snils.scan.type
      },
      set(value) {
        this.editedUser.snils.scan.type = value
      }
    },
    compMname: {
      get() {
        return this.isEdit ? this.mname : this.editedUser.userName.mname
      },
      set(value) {
        this.editedUser.userName.mname = value
      }
    },
    compName: {
      get() {
        return this.isEdit ? this.name : this.editedUser.userName.name
      },
      set(value) {
        this.editedUser.userName.name = value
      }
    },
    compSname: {
      get() {
        return this.isEdit ? this.sname : this.editedUser.userName.sname
      },
      set(value) {
        this.editedUser.userName.sname = value
      }
    },
    compSameAddress: {
      get() {
        return this.isEdit ? this.sameAddress : this.editedUser.booleanAgreements.isRegNaturalAddress.value
      },
      set(value) {
        this.editedUser.booleanAgreements.isRegNaturalAddress.value = value
      }
    },
    compSeria: {
      get() {
        return this.isEdit ? this.passportNumber.replace(/\s/g,'').substring(0, 4) : this.seria
      },
      set(value) {
        this.seria = value.trim()
      }
    },
    compNumber: {
      get() {
        return this.isEdit ? this.passportNumber.replace(/\s/g,'').substring(4) : this.number
      },
      set(value) {
        this.number = value.trim()
      }
    }
  },
  data() {
    return {
      addressList: {
        registration: [],
        actual: []
      },
      seriaError: false,
      numberError: false,
      errors: {
        name: false,
        sname: false,
        mname: false,
        birthday: false,
        birthplace: false,
        registrationAddress: false,
        postcoderegistrationAddress: false,
        actualAddress: false,
        postcodeactualAddress: false,
        issueDate: false,
        issue: false,
        department: false,
        inn: false,
        snils: false
      },
      seria: "",
      number: "",
      editedUser: {
        actualAddress: "",
        postcodeactualAddress: "",
        birthday: "",
        birthplace: "",
        booleanAgreements: {
          isElectronicDocumentManagement: {
            label: "Присоединяюсь к <a href='/client/v1/files/get?type=101' target='_blank'>соглашению об электронном документообороте</a> с УК РВМ Капитал",
            value: true
          },
          isOfficialThisRelative: {
            label: "Не являюсь публичным должностным лицом, супругом(ой) или близким родственником такого лица",
            value: true
          },
          isOwnPhone: {
            label: "Подтверждаю, что являюсь фактическим пользователем абонентского номера",
            value: true
          },
          isPersonalDataProcessing: {
            label: "Даю <a href='/client/v1/files/get?type=100' target='_blank'>согласие на обработку персональных данных</a>",
            value: true
          },
          isRegNaturalAddress: {
            label: "Совпадает с адресом проживания",
            value: true
          },
          isResident: {
            label: "Не являюсь налоговым резидентом Соединенных Штатов Америки",
            value: true
          },
        },
        email: "",
        inn: "",
        passport: {
          department: "",
          issue: "",
          issueDate: "",
          number: "",
          scan: {
            file: [],
            type: "",
          }
        },
        password: "",
        phone: "",
        qualifiedInvestor: false,
        registrationAddress: "",
        postcoderegistrationAddress: "",
        snils: {
          number: "",
          scan: {
            file: [],
            type: "",
          }
        },
        status: 2,
        userName: {
          mname: "",
          name: "",
          sname: "",
        }
      }
    }
  },
  methods: {
    ...mapActions("autocompleteModule", ["AUTOCOMPLETE_ADDRESS"]),
    onFileDelete(scan) {
      const file = {...scan}
      let type = scan.type === 1 ? "snils" : "passport"
      this.editedUser[type].scan = null
      type === 1 ? this.editedUser[type].number = null : ''
      this.$emit('deleteFile', file)
    },
    onFileLoad(event, type) {
      event.preventDefault();
      this.$emit('fileChange', event.target.files[0], type)
      let newImage = {
        data: event.target.files[0],
        name: event.target.files[0].name,
        id: undefined
      }
      this.editedUser[type].scan = { file: [], type: 0 };
      this.editedUser[type].scan.file.push(newImage)
      this.editedUser[type].scan.type = type === "snils" ? 1 : 2
      // console.log(this.editedUser[type].scan)
    },
    isSameAddress(value) {
      if (value) {
        this.editedUser.actualAddress = this.editedUser.registrationAddress
        this.editedUser.postcodeactualAddress = this.editedUser.postcoderegistrationAddress
        this.validate("actualAddress")
        this.validate("postcodeactualAddress")
      }
    },
    findList(param, value) {
      if (value?.length > 3) {
        const tis = this;
        tis.AUTOCOMPLETE_ADDRESS({address: value}).then(resp => {
          if (typeof resp != 'undefined' && Array.isArray(resp)) {
            tis.addressList[param] = resp.map(item => {
              if (typeof item.address != "undefined") {
                item.value = item.address;
                delete item.address;
              }
              return item;
            })
          }
          else if (typeof resp != 'undefined' && typeof resp.address != 'undefined') {
            resp.value = resp.address;
            delete resp.address;
            tis.addressList[param] = [resp];
          }
          else
            tis.addressList[param] = resp;
        })
      }
    },
    findInn(value) {
      this.compInn = value
      this.validate('inn')
    },
    onBlure(param, value) {
      this.editedUser[param] = value.target.value
      this.validate(param)
      if (param ==  "actualAddress" || param === "registrationAddress") {
        if (value.target.value == null || value.target.value === '')
          this.editedUser["postcode" + param] = null
        this.validate("postcode" + param);
      }
      this.isSameAddress(this.compSameAddress)
    },
    validatePassportNumber(param) {
      let maxLen = 0
      param === "seria" ? maxLen = 4 : param === 'number' ? maxLen = 6 : maxLen = 0
      this[`${param}Error`] = !(
        this[param] && this[param].length === maxLen
      );
    },

    selectAddress(param, option) {
      let address = this.addressList[param].filter(el => el === option)
      this.editedUser[`${param}Address`] = address[0].value
      this.editedUser[`postcode${param}Address`] = address[0].postcode
      this.validate(`postcode${param}Address`);
      this.validate(`${param}Address`);
      this.isSameAddress(this.compSameAddress)
      if (address[0].postcode == null)
        this.$toast.error('Извините, индекс не найден. Пожалуйста, заполните поле "Почтовый индекс" вручную.');
    },
    saveUser() {
      if (this.isEdit) {
        this.$emit('editData', this.editedUser)
      } else {
          let response = validateUserForm(this.editedUser)
          if (response.success) {
            this.$toast.info("Не забудьте приложить актуальные скан-копии документов, а также подписать все необходимые анкеты.")
            this.$emit('editData', this.editedUser)
          } else {
            this.errors[response.param] = true
            this.$toast.error(`${response.error}`)
          }
      }
    },
    validate(param) {
      this.errors[param] = !validateParam(this.editedUser, param).success
      if (param === "department") {
        this.$emit('onChangeDepartment', this.editedUser.passport.department)
      }
    },
    cancelEditing() {
      // this.editedUser = JSON.parse(JSON.stringify(this.user))
      for (const property in this.errors)
        this.errors[property] = false
      this.$emit('cancelEditData')
    },
    editAccount(params) {
      this.$emit('editBankAccount', params)
    },
    codeIsVerified(code) {
      this.$emit('codeIsVerified', code)
    },
    sendAgain() {
      this.$emit('sendAgain')
    }
  },
  watch: {
    inn: {
      immediate: true,
      handler() {
        this.editedUser.inn = this.inn
      }
    },
    isEdit: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.editedUser.actualAddress = this.actualAddress
          this.editedUser.postcodeactualAddress = this.postcodeactualAddress
          this.editedUser.birthday = this.birthday
          this.editedUser.birthplace = this.birthplace
          this.editedUser.email = this.email
          this.editedUser.inn = this.inn
          this.editedUser.passport.department = this.passportDepartment
          this.editedUser.passport.issue = this.passportIssue
          this.editedUser.passport.issueDate = this.passportIssueDate
          this.editedUser.passport.number = this.passportNumber
          this.seria = this.editedUser.passport.number.replace(/\s/g,'').substring(0, 4)
          this.number = this.editedUser.passport.number.replace(/\s/g,'').substring(4)
          this.editedUser.passport.scan.file = this.passportFile
          this.editedUser.passport.scan.type = this.passportFileType
          this.editedUser.phone= this.phone
          this.editedUser.registrationAddress= this.registrationAddress
          this.editedUser.postcoderegistrationAddress= this.postcoderegistrationAddress
          this.editedUser.snils.number = this.snilsNumber
          this.editedUser.snils.scan.file = this.snilsFile
          this.editedUser.snils.scan.type = this.snilsFileType
          this.editedUser.userName.mname = this.mname
          this.editedUser.userName.name = this.name
          this.editedUser.userName.sname = this.sname
          this.editedUser.booleanAgreements.isRegNaturalAddress.value = this.sameAddress
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.profile {
  flex: 1 1 auto;
  margin-bottom: 16px;
  text-align: left;
  @media screen and (min-width: 961px) {
    padding: 0;
  }

  & .form-container,
  & .reg-form-container {
    margin: auto;

    & h1 {
      margin-bottom: 24px;
    }

    &__form {
      margin-top: 32px;

      & h3 {
        margin-bottom: 32px
      }

      &__set {
        display: flex;
        row-gap: 24px;
        flex-direction: column;

        & .row {
          width: 100%;
          display: flex;
          column-gap: 44px;
          row-gap: 24px;
          flex-direction: column;
          flex-wrap: wrap;
          @media screen and (min-width: 961px) {
            flex-direction: row;
          }

          & .col {
            flex: 0 1 calc(100% - 100% / 3 - 2rem);

            &-3 {
              min-width: 250px;
              flex: 1 1 calc((100% / 3) - 2rem);
              @media screen and (min-width: 1280px) {
                flex: 0 0 calc((100% / 3) - 2rem);
              }
            }

            &-12 {
              flex: 1 1 100%;
            }
          }
        }
      }
    }
  }
}
</style>
