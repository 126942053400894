<template>
  <div class="modal-window">
    <div class="modal__close-btn">
      <v-icon @click="$modal.hide('AddBankAccount')">x</v-icon>
    </div>
    <template v-if="!confirmSMS">
      <h1 v-html="windowTitle" :style="styleOnSafari"></h1>
      <div class="row account-form">
        <div class="col-12">
          <rwm-autocomplete
            v-mask="'#########'"
            label="БИК банка"
            :list="bikList"
            @input="findList"
            @select="selectBank"
            :value="_bik"
          />
        </div>
        <div class="col-12">
          <rwm-text-field
            v-model="bankName"
            label="Наименование банка"
            readonly
          />
        </div>
        <div class="col-12">
          <rwm-text-field
            v-model="corrAccount"
            label="Корреспондентский счет"
            readonly
          />
        </div>
        <div class="col-12">
          <rwm-text-field
            v-model="accountNumber"
            label="Номер счета в банке"
            v-mask="'####################'"
            @blur="validate()"
          />
        </div>
        <div class="col-12">
          <rwm-text-field
            v-model="accountName"
            label="Наименование счета"
            @input="isValidate = true"
            @blur="validate()"
          />
        </div>
      </div>
      <rwm-button width="100%" @click="confirm()" :disabled="!isValidate"
        >Сохранить</rwm-button
      >
    </template>
    <template v-else>
      <h1>Подтверждение<br />действия</h1>
      <rwm-sms-checking
        v-model="timer"
        :time="60"
        @sendAgain="sendAgain()"
        @verified="codeIsVerified"
        isShow
      />
    </template>
  </div>
</template>

<script>
import VIcon from "../icons/VIcon";
import RwmButton from "../RWMButton/RwmButton";
import RwmTextField from "../RWMTextField/RwmTextField";
import RwmSmsChecking from "../RWMSMSChecking/RwmSmsChecking";
import RwmAutocomplete from "../RWMAutocomplete/RwmAutocomplete";
import { mapActions, mapGetters } from "vuex";
import { validateRs } from "../../models/Validation";

export default {
  name: "AddBankAccount",
  components: {
    RwmAutocomplete,
    RwmSmsChecking,
    RwmTextField,
    RwmButton,
    VIcon,
  },
  props: ["params"],
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("autocompleteModule", ["GET_BANKS_AUTOCOMPLETE"]),
    windowTitle() {
      let title = `${
        this.isEdit ? "Редактировать" : "Добавить"
      } банковские<br />реквизиты`;
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        title = `${
          this.isEdit ? "Редактировать" : "Добавить"
        } банковские реквизиты`;
      }
      return title;
    },
    styleOnSafari() {
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return "font-size: 24px";
      } else return "";
    },
    isEdit() {
      return JSON.stringify(this.params) !== "{}";
    },
    bikList() {
      return this.GET_BANKS_AUTOCOMPLETE?.map((e) => e.bik);
    },
    _bik: {
      get() {
        return this.isEdit ? this.params.bik : "";
      },
      set(val) {
        this.bik = val;
      },
    },
  },
  data() {
    return {
      text: "",
      timer: false,
      confirmSMS: false,
      bik: "",
      bankName: "",
      corrAccount: "",
      accountNumber: "",
      accountName: "",
      isValidate: false,
    };
  },
  methods: {
    ...mapActions("autocompleteModule", ["AUTOCOMPLETE_BANK"]),
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("bankAccModule", [
      "CREATE_BANK_ACCOUNT",
      "UPDATE_BANK_ACCOUNT",
    ]),
    sendAgain() {
      this.timer = true;
    },
    codeIsVerified(code) {
      this.CONFIRM_SMS({ phone: this.getUSER.phone, key: code }).then(
        (success) => {
          if (success) {
            if (!this.isEdit) {
              this.CREATE_BANK_ACCOUNT({
                name: this.accountName,
                bank_name: this.bankName,
                bik: this.bik,
                correspondent_account: this.corrAccount,
                bank_account: this.accountNumber,
              }).finally(() => {
                if (this.getERROR?.length > 0) {
                  this.$toast.error(`${this.getERROR[0]}`);
                } else {
                  this.$modal.hide("AddBankAccount");
                  this.$toast.success("Новый счет успешно добавлен");
                }
              });
            } else {
              this.UPDATE_BANK_ACCOUNT({
                id: this.params.id,
                name: this.accountName,
                bank_name: this.bankName,
                bik: this.bik,
                correspondent_account: this.corrAccount,
                bank_account: this.accountNumber,
              }).finally(() => {
                if (this.getERROR?.length > 0) {
                  this.$toast.error(`${this.getERROR}`);
                } else {
                  this.$modal.hide("AddBankAccount");
                  this.$toast.success(
                    `Счет ${this.accountName} успешно отредактирован`
                  );
                }
              });
            }
          } else {
            this.$toast.error(`${this.getERROR[0]}`);
          }
        }
      );
    },
    findList(value) {
      if (value?.length > 3) {
        this.AUTOCOMPLETE_BANK({ bik: value });
      }
    },
    selectBank(bik) {
      let bank = this.GET_BANKS_AUTOCOMPLETE.filter((bank) => bank.bik === bik);
      this.bik = bank[0].bik;
      this.bankName = bank[0].bank_name;
      this.corrAccount = bank[0].correspondent_account;
    },
    confirm() {
      this.validate();
      if (this.isValidate) {
        this.REQUEST_SMS({ phone: this.getUSER.phone, action: 'user_bank_account' }).then((success) => {
          if (success) {
            this.confirmSMS = true;
            this.timer = true;
            this.loading = false;
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        });
      }
    },
    validate() {
      let error = {};
      this.isValidate =
        validateRs(this.accountNumber, this.bik, error) === true && this.accountName !== "";
      if (JSON.stringify(error) !== "{}") {
        this.$toast.error(error.message);
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.bik = this.params.bik;
      this.bankName = this.params.bank;
      this.corrAccount = this.params.corrAccount;
      this.accountNumber = this.params.account;
      this.accountName = this.params.name;
    }
  },
};
</script>

<style scoped lang="scss">
.modal-window {
  & h1 {
    margin-bottom: 44px;
  }
  & .row {
    width: 100%;
    display: flex;
    row-gap: 24px;
    &.account-form {
      margin: 44px 0;
    }
    & .col-12 {
      flex: 1 1 100%;
    }
  }
}
</style>
