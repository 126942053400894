const validateParam = (user, param) => {
    let er = {}, mail = "", phone = ""
    switch (param) {
        case "userName":
            if (user[param]?.name.length > 0 && user[param]?.sname.length > 0 && user[param]?.mname.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поля ФИО являются обязательными"}
        case "name":
            if (user.userName[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле имя является обязательным"}

        case "sname":
            if (user.userName[param]?.length) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле фамилия является обязательным"}

        case "mname":
            if (user.userName[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле отчество является обязательным"}

        case "actualAddress":
            if (user[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле полный адрес регистрации является обязательным"}

        case "postcodeactualAddress":
            if (user[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле индекс для адреса проживания является обязательным"}

        case "registrationAddress":
            if (user[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле полный адрес регистрации является обязательным"}

        case "postcoderegistrationAddress":
            if (user[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле индекс адреса регистрации является обязательным"}

        case "birthplace":
            if (user[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле место рождения - город является обязательным"}

        case "passport":
            if (user[param]?.department.length > 0 && user[param]?.issue.length > 0 && user[param]?.issueDate.length > 0 && user[param]?.number.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: "Поля паспортных данных являются обязательными"}

        case "department":
            if (user.passport[param]?.length > 0 && user.passport[param]?.toString().replace("-", "").length === 6) {
                return {success: true, error: null}
            } else return {success: false, error: `Поле ${param} заполнено неверно`}

        case "issue":
            if (user.passport[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: `Поле ${param} заполнено неверно`}

        case "issueDate":
            if (user.passport[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: `Поле ${param} заполнено неверно`}

        case "number":
            if (user.passport[param]?.length > 0 && user.passport[param]?.toString().replace(" ", "").length === 10) {
                return {success: true, error: null}
            } else return {success: false, error: `Поле ${param} заполнено неверно`}

        case "phone":
            phone = /^\+7[\d() -]{4,14}\d$/
            if (phone.test(user[param])) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле телефон заполнено неверно"}

        case "email":
            mail = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
            if (mail.test(user[param])) {
                return {success: true, error: null}
            } else return {success: false, error: "Поле email заполнено неверно"}

        case "inn":
            if (!validateInn(user[param], er)?.code) {
                return {success: true, error: null}
            } else return {success: false, error: er.message}

        case "snils":
            if (validateSnils(user[param].number.replace(/-|\s/g, ''), er) === true) {
                return {success: true, error: null}
            } else return {success: false, error: er.message}

        default:
            if (user[param]?.length > 0) {
                return {success: true, error: null}
            } else return {success: false, error: `Поле ${param} заполнено неверно`}
    }
}

const validatePassword = (password) => {
    let pattern = /(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/g
    return password && password.length > 0 && pattern.test(password) ? {success: true, error: null} : {success: false, error: "Придумайте пароль, соответствующий требованиям безопасности"}
}

const validateUserForm = (user) => {
    if (JSON.stringify(user) !== "{}") {
        let result = null
        const params = Object.keys(user)
        params.forEach(param => {
            if (param === "booleanAgreements" || param === "qualifiedInvestor" || param === "status" || param === "password") {
                return
            } else if (validateParam(user, param).success) {
                return
            } else result = {...validateParam(user, param), param}
        })
        return result ? result : {success: true, error: null}
    } else {
        return {success: false, error: 'Пользователь не найден'}
    }
}

const validateInn = function (inn, error) {
    var result = false;
    if (typeof inn === 'number') {
        inn = inn.toString();
    } else if (typeof inn !== 'string') {
        inn = '';
    }
    if (!inn.length) {
        error.code = 1;
        error.message = 'ИНН пуст';
    } else if (/[^0-9]/.test(inn)) {
        error.code = 2;
        error.message = 'ИНН может состоять только из цифр';
    } else if ([10, 12].indexOf(inn.length) === -1) {
        error.code = 3;
        error.message = 'ИНН может состоять только из 10 или 12 цифр';
    } else {
        var checkDigit = function (inn, coefficients) {
            var n = 0;
            for (var i in coefficients) {
                n += coefficients[i] * inn[i];
            }
            return parseInt(n % 11 % 10);
        };
        switch (inn.length) {
            case 10:
                var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if (n10 === parseInt(inn[9])) {
                    result = true;
                }
                break;
            case 12:
                var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
                    result = true;
                }
                break;
        }
        if (!result) {
            error.code = 4;
            error.message = 'Неправильное контрольное число';
        }
    }
    return result ? result : error;
}

const validateSnils = function (snils, error) {
    var result = false;
    if (typeof snils === 'number') {
        snils = snils.toString();
    } else if (typeof snils !== 'string') {
        snils = '';
    }
    if (!snils.length) {
        error.code = 1;
        error.message = 'СНИЛС пуст';
    } else if (/[^0-9]/.test(snils)) {
        error.code = 2;
        error.message = 'СНИЛС может состоять только из цифр';
    } else if (snils.length !== 11) {
        error.code = 3;
        error.message = 'СНИЛС может состоять только из 11 цифр';
    } else {
        var sum = 0;
        for (var i = 0; i < 9; i++) {
            sum += parseInt(snils[i]) * (9 - i);
        }
        var checkDigit = 0;
        if (sum < 100) {
            checkDigit = sum;
        } else if (sum > 101) {
            checkDigit = parseInt(sum % 101);
            if (checkDigit === 100) {
                checkDigit = 0;
            }
        }
        if (checkDigit === parseInt(snils.slice(-2))) {
            result = true;
        } else {
            error.code = 4;
            error.message = 'Неправильное контрольное число';
        }
    }
    return result ? result : error;
}

function validateBik(bik, error) {
    var result = false;
    if (typeof bik === 'number') {
        bik = bik.toString();
    } else if (typeof bik !== 'string') {
        bik = '';
    }
    if (!bik.length) {
        error.code = 1;
        error.message = 'БИК пуст';
    } else if (/[^0-9]/.test(bik)) {
        error.code = 2;
        error.message = 'БИК может состоять только из цифр';
    } else if (bik.length !== 9) {
        error.code = 3;
        error.message = 'БИК может состоять только из 9 цифр';
    } else {
        result = true;
    }
    return result;
}

const validateRs = function (rs, bik, error) {
    var result = false;
    if (validateBik(bik, error)) {
        if (typeof rs === 'number') {
            rs = rs.toString();
        } else if (typeof rs !== 'string') {
            rs = '';
        }
        if (!rs.length) {
            error.code = 1;
            error.message = 'Р/С пуст';
        } else if (/[^0-9]/.test(rs)) {
            error.code = 2;
            error.message = 'Р/С может состоять только из цифр';
        } else if (rs.length !== 20) {
            error.code = 3;
            error.message = 'Р/С может состоять только из 20 цифр';
        } else {
            var bikRs = bik.toString().slice(-3) + rs;
            var checksum = 0;
            var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
            for (var i in coefficients) {
                checksum += coefficients[i] * (bikRs[i] % 10);
            }
            if (checksum % 10 === 0) {
                result = true;
            } else {
                error.code = 4;
                error.message = 'Проверьте реквизиты';
            }
        }
    }
    return result ? result : error;
}

export {
    validateInn,
    validateSnils,
    validateRs,
    validateUserForm,
    validateParam,
    validatePassword
}
