<template>
    <div class="delimiter" />
</template>

<script>
    export default {
        name: "Delimiter"
    }
</script>

<style scoped lang="scss">
    .delimiter {
        width: 100%;
        opacity: 0.5;
        background-color: $RWM-grey-light;
        height: 1px;
    }
</style>