<template>
    <div class="bank-account">
        <p class="anketa">{{name}}</p>
        <div class="row">
            <div class="col-12">
                <rwm-text-field width="100%" label="Наименование банка" :value="bank" disabled />
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <rwm-text-field width="100%" label="Номер счета в банке" :value="account" disabled />
            </div>
            <div class="col-3">
                <rwm-text-field width="100%" label="БИК банка" :value="bik" disabled />
            </div>
            <div class="col-3">
                <rwm-text-field width="100%" label="Корреспондентский счет" :value="corrAccount" disabled />
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <rwm-button width="100%" outlined @click="$emit('editBankAccount', {name: name, account: account, bik: bik, bank: bank, corrAccount: corrAccount, id: id})">Редактировать</rwm-button>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmTextField from "../RWMTextField/RwmTextField";
    import RwmButton from "../RWMButton/RwmButton";
    export default {
        name: "BankAccount",
        components: {RwmButton, RwmTextField},
        props: ["name", "bik", "bank", "account", "corrAccount", "id"],
    }
</script>

<style scoped lang="scss">
    .bank-account {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        row-gap: 24px;
        & .anketa {
            flex: 1 1 100%;
            width: 100%;
        }
        & .row {
           display: flex;
            row-gap: 24px;
            column-gap: 44px;
            justify-content: space-between;
            flex-direction: column;
            flex-wrap: wrap;
            @media screen and (min-width: 900px) {
                flex-direction: row;
            }
            & .col {
                &-12 {
                    width: 100%;
                }
                &-3 {
                    min-width: 250px;
                    flex: 1 1 calc((100% / 3) - 2rem);
                    @media screen and (min-width: 1280px) {
                        flex: 0 0 calc((100% / 3) - 2rem);
                    }
                }
            }
        }
    }
</style>