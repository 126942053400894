<template>
  <div class="client-profile">
    <profile
      :actualAddress="getUSER.actualAddress"
      :postcodeactual-address="getUSER.postcodeactualAddress"
      :birthday="getUSER.birthday"
      :birthplace="getUSER.birthplace"
      :email="getUSER.email"
      :inn="getUSER.inn"
      :registration-method="getUSER.registrationMethod"
      :passportDepartment="getUSER.passport.department"
      :passportIssue="getUSER.passport.issue"
      :passportIssueDate="getUSER.passport.issueDate"
      :passportNumber="getUSER.passport.number"
      :passport-file="getUSER.passport.scan.file"
      :passport-file-type="getUSER.passport.scan.type"
      :phone="getUSER.phone"
      :registrationAddress="getUSER.registrationAddress"
      :postcoderegistration-address="getUSER.postcoderegistrationAddress"
      :snilsNumber="getUSER.snils.number"
      :snils-file="getUSER.snils.scan.file"
      :snilsFileType="getUSER.snils.scan.type"
      :mname="getUSER.userName.mname"
      :name="getUSER.userName.name"
      :sname="getUSER.userName.sname"
      :same-address="getUSER.booleanAgreements.isRegNaturalAddress.value"
      :isEdit="isEdit"
      :accountsList="getBANK_ACCOUNTS"
      :showConfirmation="showConfirmation"
      :timer="timer"
      :issued-by-list="issuedByList"
      :inn-list="innList"
      @EditPass="openEditPassModal()"
      @editData="openConfirmModal"
      @cancelEditData="cancelEditData()"
      @addBankAccount="openAddBankAccountModal()"
      @editBankAccount="openAddBankAccountModal"
      @codeIsVerified="codeIsVerified"
      @timerChange="timerChange"
      @openFile="openFile"
      @deleteFile="deleteFile"
      @fileChange="fileChange"
      @sendAgain="openConfirmModal(getUSER)"
      @onChangeDepartment="getIssuedByList"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Profile from "../../components/pages/cabinet/Profile";
import ChangePassword from "../../components/modals/ChangePassword";
import InfoModal from "../../components/modals/InfoModal";
// import ImgModal from "../../components/modals/ImgModal";
import AddBankAccount from "../../components/modals/AddBankAccount";
import api from "../../api";

export default {
  name: "ClientProfile",
  components: { Profile },
  watch: {
    userChanged() {
      this.cancelEditData();
    },
    userChangedBankAccounts() {
      this.GET_BANK_ACCOUNTS();
    }
  },
  computed: {
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("bankAccModule", ["getBANK_ACCOUNTS"]),
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AutoUpdateModule", ["userChanged", "userChangedBankAccounts"])
  },
  data() {
    return {
      isEdit: true,
      timer: false,
      showConfirmation: false,
      issuedByList: [],
      innList: [],
    };
  },
  methods: {
    ...mapActions("bankAccModule", ["GET_BANK_ACCOUNTS"]),
    ...mapActions("regModule", [
      "SAVE_NEW_USER",
      "DELETE_FILE",
      "DOWNLOAD_FILE",
      "UPDATE_USER_DATA",
      "MODIFY_USER",
    ]),
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("AuthModule", ["ADD_USER_DATA", "GET_USER"]),
    cancelEditData() {
      this.isEdit = true;
      this.GET_USER();
    },
    async getIssuedByList(department) {
      const response = await api.searchPassportQuery({data: {passport_code: department}})
      if (response.success && response.result.data) this.issuedByList = response.result.data.map(el => el.name);
      else this.$toast.error("Не найден код подразделения. Пожалуйста, введите данные в поле \"Кем выдан паспорт\" вручную.");
    },
    fileChange(file, type) {
      let typedData = this.getUSER[type];
      const numType = type === "passport" ? 2 : type === "snils" ? 1 : 0;
      typedData.scan.file.length = 0;
      typedData.scan.type = numType;
      typedData.scan.file.push({ name: file.name, data: file });
      // for save image to local storage
      // let reader = new FileReader();
      // reader.onload = () => {
      //     typedData.scan.file.push({ name: file.name, data: reader.result })
      //     typedData.scan.type = numType
      //     this.ADD_USER_DATA({[type] : { ...typedData }})
      // }
      // reader.readAsDataURL(file)
      //
    },
    deleteFile(scan) {
      this.DELETE_FILE(scan).then((resp) => {
        resp
          ? (this.$toast.success("Файл удален"), this.GET_USER())
          : this.$toast.error("Файл не был удален");
      });
    },
    timerChange(timer) {
      this.timer = timer;
    },
    beforeOpen() {
      let body = document.body;
      body.classList.add("modal-open");
    },
    beforeClose() {
      let body = document.body;
      body.classList.remove("modal-open");
    },
    openFile(param) {
      const id = this.getUSER[param]?.scan?.file[0]?.id;
      const name = this.getUSER[param]?.scan?.file[0]?.name;
      this.DOWNLOAD_FILE({ id: id, name: name });
      // let title = "Скан"
      // param === 'passport' ? title = `${title} пасспорта` : title = `${title} СНИЛС`
      // this.$modal.show(
      //     ImgModal,
      //     {
      //         title: title,
      //         src: src
      //     }, {
      //         width: 522,
      //         height: 'auto',
      //         clickToClose: true,
      //         scrollable: true,
      //         name: "ImgModal",
      //     }, {
      //         'before-open': this.beforeOpen,
      //         'before-close': this.beforeClose,
      //         // 'closed': setTimeout(() => {this.$router.push('/login')}, 1000)
      //     })
    },
    openConfirmModal(user) {
      if (this.isEdit) {
        this.$modal.show(
          InfoModal,
          {
            title: "Изменение данных",
            text: "При изменении данных в профиле не забудьте приложить актуальные скан-копии документов. Также Вам будет необходимо подписать документы, перечень которых Вы сможете посмотреть в разделе Документы.",
            icon: "Warning",
          },
          {
            width: 522,
            height: "auto",
            clickToClose: false,
            scrollable: true,
            name: "InfoModal",
          },
          {
            "before-open": this.beforeOpen,
            "before-close": this.beforeClose,
            // 'closed': setTimeout(() => {this.$router.push('/login')}, 1000)
          }
        );
        this.isEdit = false;
      } else {
        this.REQUEST_SMS({ phone: this.getUSER.phone, action: 'profile_update' }).then((success) => {
          if (success) {
            this.showConfirmation = true;
            this.timer = true;
            this.SAVE_NEW_USER(user);
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        });
      }
    },
    codeIsVerified(code) {
      this.CONFIRM_SMS({ phone: this.getUSER.phone, key: code }).then(
        (success) => {
          if (success) {
            this.UPDATE_USER_DATA(this.getUSER).then((success) => {
              if (!success) {
                this.$toast.error(`${this.getERROR}`);
                this.GET_USER();
              } else {
                this.$toast.success("Данные успешно обновлены");
                if (this.getUSER.status > 2) {
                  this.openModal("Уважаемый клиент!<br>" +
                          "Вы изменили персональные данные и Вам необходимо переподписать пакет документов",
                          {location: "/cabinet/documents?next" },
                          "Продолжить");
                }
              }
            });
            this.showConfirmation = false;
            this.isEdit = true;
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    openEditPassModal() {
      this.$modal.show(
        ChangePassword,
        {
          phone: this.getUSER.phone,
        },
        {
          width: 522,
          height: "auto",
          clickToClose: false,
          scrollable: true,
          name: "ChangePassword",
        },
        {
          "before-open": this.beforeOpen,
          "before-close": this.beforeClose,
          // 'closed': setTimeout(() => {this.$router.push('/login')}, 1000)
        }
      );
    },
    openAddBankAccountModal(params = {}) {
      this.$modal.show(
        AddBankAccount,
        {
          params,
        },
        {
          width: 522,
          height: "auto",
          clickToClose: false,
          scrollable: true,
          name: "AddBankAccount",
        },
        {
          "before-open": this.beforeOpen,
          "before-close": this.beforeClose,
          // 'closed': setTimeout(() => {this.$router.push('/login')}, 1000)
        }
      );
    },

    openModal(text, proceed = false, buttonText = "Продолжить") {
      this.$modal.show(
              InfoModal,
              {
                title: "Персональные данные",
                text: text,
                icon: "Warning",
                buttonText: buttonText,
                proceed: proceed
              },
              {
                width: 522,
                height: "auto",
                clickToClose: false,
                scrollable: true,
                name: "InfoModal",
              },
              {
                "before-open": this.beforeOpen,
                "before-close": this.beforeClose,
              }
      );
    },
  },
  mounted() {
    this.GET_BANK_ACCOUNTS();
  },
};
</script>

<style scoped lang="scss"></style>
