<template>
  <div class="modal-window">
    <div class="modal__close-btn">
      <v-icon @click="$modal.hide('ChangePassword')">x</v-icon>
    </div>
    <template v-if="!confirmSMS">
      <h1>Изменить пароль</h1>
      <div class="modal-window__info">
        <p>Минимальная длина пароля 8 символов.</p>
        <p>
          Пароль не может быть пустым, содержать пробелы и состоять только из
          букв или цифр.
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <rwm-text-field
            v-model="change.OldPass"
            :error="errors.OldPass"
            :type="isOpenOldPass ? 'text' : 'password'"
            label="Старый пароль"
            @blur="validatePassword('Old')"
            @onAppend="isOpenOldPass = !isOpenOldPass"
          >
            <div slot="append-icon">
              <transition name="fade">
                <v-icon v-if="isOpenOldPass" @click="isOpenOldPass=!isOpenOldPass">view</v-icon>
                <v-icon v-else @click="isOpenOldPass=!isOpenOldPass">close</v-icon>
              </transition>
            </div>
          </rwm-text-field>
        </div>
        <div class="col-12">
          <rwm-text-field
            v-model="change.NewPass"
            :error="errors.NewPass"
            :type="isOpenNewPass ? 'text' : 'password'"
            label="Новый пароль"
            @blur="validatePassword('New')"
            @onAppend="isOpenNewPass = !isOpenNewPass"
          >
            <div slot="append-icon">
              <transition name="fade">
                <v-icon v-if="isOpenNewPass" @click="isOpenNewPass=!isOpenNewPass">view</v-icon>
                <v-icon v-else @click="isOpenNewPass=!isOpenNewPass">close</v-icon>
              </transition>
            </div>
          </rwm-text-field>
        </div>
        <div class="col-12">
          <rwm-text-field
            v-model="change.ConfPass"
            :error="errors.ConfPass"
            :type="isOpenConfPass ? 'text' : 'password'"
            label="Повторите пароль"
            @blur="validatePassword('Conf')"
            @onAppend="isOpenConfPass = !isOpenConfPass"
          >
            <div slot="append-icon">
              <transition name="fade">
                <v-icon v-if="isOpenConfPass" @click="isOpenConfPass=!isOpenConfPass">view</v-icon>
                <v-icon v-else @click="isOpenConfPass=!isOpenConfPass">close</v-icon>
              </transition>
            </div>
          </rwm-text-field>
        </div>
      </div>
      <div class="row actions">
        <div class="col-6">
          <rwm-button width="100%" :disabled="!isVerified" @click="onSave()"
            >Сохранить</rwm-button
          >
        </div>
        <div class="col-6">
          <rwm-button
            width="100%"
            outlined
            @click="$modal.hide('ChangePassword')"
            >Отказаться</rwm-button
          >
        </div>
      </div>
    </template>
    <template v-else>
      <h1>Подтверждение<br />действия</h1>
      <rwm-sms-checking
        v-model="timer"
        :time="60"
        @sendAgain="sendAgain()"
        @verified="codeIsVerified"
        isShow
      />
    </template>
  </div>
</template>

<script>
import VIcon from "../icons/VIcon";
import RwmTextField from "../RWMTextField/RwmTextField";
import RwmButton from "../RWMButton/RwmButton";
import { mapActions, mapGetters } from "vuex";
import RwmSmsChecking from "../RWMSMSChecking/RwmSmsChecking";
export default {
  name: "ChangePassword",
  components: { RwmSmsChecking, RwmButton, RwmTextField, VIcon },
  props: {
    phone: String,
  },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    isVerified() {
      return Object.keys(this.errors).some((el) => this.errors[el] === false);
    },
  },
  data() {
    return {
      change: {
        OldPass: "",
        NewPass: "",
        ConfPass: "",
      },
      isOpenConfPass: false,
      isOpenOldPass: false,
      isOpenNewPass: false,
      errors: {
        OldPass: false,
        NewPass: false,
        ConfPass: false,
      },
      confirmSMS: false,
      timer: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("passwordActionsModule", ["CHANGE_PASSWORD"]),
    sendAgain() {
      if (!this.timer) {
        this.REQUEST_SMS({ phone: this.phone, action: 'change_password' }).then((success) => {
          if (success) {
            this.confirmSMS = true;
            this.timer = true;
            this.loading = false;
          } else {
            this.$toast.error(`${this.getERROR[0]}`);
          }
        });
      }
    },
    onSave() {
      Object.keys(this.change).forEach((el) => {
        this.validatePassword(el.replace("Pass", ""));
      });
      if (this.isVerified && this.phone) {
        this.sendAgain();
      }
    },
    codeIsVerified(code) {
      this.loading = true;
      this.CONFIRM_SMS({ phone: this.phone, key: code }).then((success) => {
        if (success) {
          this.CHANGE_PASSWORD({
            password: this.change.OldPass,
            newPassword: this.change.NewPass,
            repeatPassword: this.change.ConfPass,
          }).finally(() => {
            this.loading = false;
            this.$modal.hide("ChangePassword");
            this.$toast.success("Пароль успешно изменен");
            if (this.getERROR?.length > 0) {
              this.$toast.error(`${this.getERROR[0]}`);
            }
          });
        } else {
          this.$toast.error(`${this.getERROR[0]}`);
        }
      });
    },
    validatePassword(param) {
      let pattern =
        /(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/g;
      pattern.test(this.change[`${param}Pass`])
        ? (this.errors[`${param}Pass`] = false)
        : (this.errors[`${param}Pass`] = true);
      if (!this.errors.OldPass && !this.errors.NewPass) {
        if (this.change.NewPass === this.change.ConfPass) {
          this.errors.isPasswordConfirm = false;
        } else {
          this.errors.isPasswordConfirm = true;
        }
      } else this.errors.isPasswordConfirm = true;
    },
  },
};
</script>

<style scoped lang="scss">
.modal-window {
  & h1 {
    margin-bottom: 44px;
  }
  &__info {
    color: $RWM-grey;
    margin: 44px 0;
    font-weight: 500;
    & p {
      margin-bottom: 22px;
    }
  }
  & .row {
    row-gap: 24px;
    & .col-12 {
      width: 100%;
      min-width: 100%;
    }
    & .col-6 {
      width: calc(50% - 28px);
    }
    &.actions {
      column-gap: 56px;
      margin-top: 44px;
    }
  }
}
</style>
