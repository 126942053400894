<template>
    <rwm-text-field
            v-bind="$attrs"
            v-model="text"
            v-mask="mask || null"
            :label="label"
            @input="onInput"
            :error="error"
            @blur="$emit('blur', $event)"
            v-click-outside="hideList"
            @click="isShowList = true"
    >
        <template slot="append-icon">
            <v-icon :hovering="isHasList" @click="switchList">{{!isShowList ? "caret-down" :
                "caret-up"}}
            </v-icon>
        </template>
        <template slot="list">
            <transition name="slide" mode="out-in">
                <div :class="['rwm-autocomplete__options', {'list-show' : isShowList}]">
                    <p v-for="option in list" :key="typeof option.value != 'undefined' ? option.value : option" class="rwm-autocomplete__options-option"
                       @click="select(option)">
                        {{typeof option.value != 'undefined' ? option.value : option}}
                    </p>
                </div>
            </transition>
        </template>
    </rwm-text-field>

</template>

<script>
    import RwmTextField from "../RWMTextField/RwmTextField";
    import VIcon from "../icons/VIcon";
    // import ClickOutside from 'vue-click-outside'

    export default {
        name: "RwmAutocomplete",
        components: {VIcon, RwmTextField},
        props: ["list", "value", "label", "error", "mask"],
        directives: {
          clickOutside: {
            bind: function (el, binding, vnode) {
              el.eventSetDrag = function () {
                el.setAttribute('data-dragging', 'yes');
              }
              el.eventClearDrag = function () {
                el.removeAttribute('data-dragging');
              }
              el.eventOnClick = function (event) {
                let dragging = el.getAttribute('data-dragging');
                // Check that the click was outside the el and its children, and wasn't a drag
                if (!(el === event.target || el.contains(event.target)) && !dragging) {
                  // call method provided in attribute value
                  vnode.context[binding.expression](event);
                }
              };
              document.addEventListener('touchstart', el.eventClearDrag);
              document.addEventListener('touchmove', el.eventSetDrag);
              document.addEventListener('click', el.eventOnClick);
              document.addEventListener('touchend', el.eventOnClick);
            }, unbind: function (el) {
              document.removeEventListener('touchstart', el.eventClearDrag);
              document.removeEventListener('touchmove', el.eventSetDrag);
              document.removeEventListener('click', el.eventOnClick);
              document.removeEventListener('touchend', el.eventOnClick);
              el.removeAttribute('data-dragging');
            },
          }
        },
        computed: {
            isHasList() {
                return this.list?.length > 0
            },
        },
        data: () => ({
            isShowList: false,
            text: ""
        }),
        watch: {
            value(newVal) {
                this.text = newVal
            },
            isHasList(isHave) {
              this.isShowList = isHave;
            },
        },
        methods: {
            onInput(value) {
                setTimeout(function () {
                    this.$emit('input', value)
                    this.isShowList = this.isHasList
                }.bind(this), 100)
            },
            select(option) {
                this.text = typeof option.value != 'undefined' ? option.value: option;
                this.$emit('select', option)
                this.hideList()
            },
            switchList() {
                this.isHasList && !this.$attrs.disabled ? this.isShowList = !this.isShowList : ''
            },
            hideList() {
              this.isShowList = false
            }
        },
        mounted() {
            this.value ? this.text = this.value : ''
        }
    }
</script>

<style scoped lang="scss">
    .rwm-autocomplete__options {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% + 3px);
        background-color: $RWM-white;
        z-index: 50;
        border-radius: 5px;
        border: 1px solid $RWM-grey;
        padding: 5px 10px;
        max-height: 150px;
        overflow-y: auto;

        &-option {
            display: flex;
            flex: 1 1 auto;
            padding: 5px 10px;
            margin: 0 -10px;
            transition: background-color 300ms, color 300ms;
            font-weight: 500;
            font-size: 15px;
            line-height: 100%;
            cursor: pointer;

            &:hover {
                background-color: $RWM-grey-super-light;
                color: $RWM-white;
            }
        }

        &.list-show {
            display: flex;
            flex-direction: column;
        }
    }
</style>
